<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="饿了么外卖" name="first"></el-tab-pane>
			<el-tab-pane label="饿了么零售" name="second"></el-tab-pane>
		</el-tabs>
		<el-button type="primary" @click="authorize">授权店铺</el-button>
		<div style="margin-top: 10px;">
			<el-table border :data="shoplist" style="width: 100%">
				<el-table-column prop="name" label="店铺名称">
				</el-table-column>
				<el-table-column prop="shopId" label="平台店铺ID" width="280">
				</el-table-column>
				<el-table-column prop="isOpen" label="状态" width="180">
					<template slot-scope="scope">
						<el-tag :type="scope.row.isOpen == 0 ? 'primary' : 'success'" disable-transitions>
							{{scope.row.isOpen == 0 ? '未营业' : '营业中'}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="primary" @click="handleEdit(scope.$index,scope.row)" size="small">编辑
						</el-button>
						<el-button @click="delClick(scope.$index,scope.row)" type="danger" size="small">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="添加店铺" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose2">
			<div class="u-t-flex">
				<div class="u-t-flex" style="flex:1;">
					<img class="dashboard__logo" style="width: 70px;" src="@/assets/elm_img.jpeg" />
				</div>
				<div class="u-t-flex" style="flex:1;">
					<img class="dashboard__logo" style="width: 70px;" src="@/assets/huajibao.png" />
				</div>
			</div>
			<div style="text-align: center;">请在新窗口后进行授权，完成后点击授权成功或刷新页面！</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible1 = false">取 消</el-button>
				<el-button type="primary" v-if="isact == 0" @click="shouquan">授 权</el-button>
				<el-button type="primary" v-if="isact == 1" @click="wancheng">授权完成</el-button>
			</span>
		</el-dialog>
		<el-dialog title="编辑店铺" :visible.sync="dialog1" width="50%" :before-close="handleClose1">
			<div>
				<el-descriptions v-if="shopinfo && shopinfo.OShop" class="margin-top" title="店铺信息" :column="2">
					<el-descriptions-item label="店铺Id">{{shopinfo.shopId}}</el-descriptions-item>
					<el-descriptions-item label="店铺地址">{{shopinfo.OShop.addressText}}</el-descriptions-item>
					<el-descriptions-item label="店铺所在省" :span1="2">{{shopinfo.OShop.provinceName}}
					</el-descriptions-item>
					<el-descriptions-item label="店铺所在地级市">{{shopinfo.OShop.cityName}}</el-descriptions-item>
					<el-descriptions-item label="店铺所在县级市或区">{{shopinfo.OShop.districtName}}</el-descriptions-item>
					<el-descriptions-item label="店铺默认配送费">{{shopinfo.OShop.agentFee}}</el-descriptions-item>
					<el-descriptions-item label="店铺营业状态">{{busyLevel[shopinfo.OShop.busyLevel]}}</el-descriptions-item>
					<el-descriptions-item label="城市Id">{{shopinfo.OShop.cityId}}</el-descriptions-item>
					<el-descriptions-item label="城市区号">{{shopinfo.OShop.cityCode}}</el-descriptions-item>
					<el-descriptions-item label="店铺关闭的原因">{{shopinfo.OShop.closeDescription}}</el-descriptions-item>
					<el-descriptions-item label="起送价">{{shopinfo.OShop.deliverAmount}}</el-descriptions-item>
					<el-descriptions-item label="配送区域详情">{{shopinfo.OShop.deliverAmount}}</el-descriptions-item>
					<el-descriptions-item label="2周内的平均送餐时间">{{shopinfo.OShop.deliverSpent}}</el-descriptions-item>
				</el-descriptions>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialog1 = false">取 消</el-button>
				<el-button type="primary" @click="dialog1 = false">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="添加店铺" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
			<div class="u-t-flex">
				<div class="u-t-flex" style="flex:1;">
					<img class="dashboard__logo" style="width: 70px;" src="@/assets/elm_img.jpeg" />
				</div>
				<div class="u-t-flex" style="flex:1;">
					<img class="dashboard__logo" style="width: 70px;" src="@/assets/huajibao.png" />
				</div>
			</div>
			<div style="text-align: center;">授权给麦芽田后，将自动同步数据到系统中！</div>
			<div>
				<div id="auth-box">
					<div class="steps-list">
						<div class="item">
							<p style="margin: 10px 0px;" class="step">第一步</p>
							<div class="title">
								登录饿了么零售后台-门店设置中找到门店ID
							</div>
						</div>
						<div class="item">
							<p class="step">第二步</p>
							<div class="title">将门店ID粘贴到下方的输入框中</div>
							<div class="after-selection-form-input">
								<el-input v-model="shop_id" placeholder="输入门店ID"></el-input>
							</div>
						</div>
						<div class="item">
							<p class="step">第三步</p>
							<div class="title">点击开始绑定进行店铺授权，如有疑问可先查看绑定说明</div>
						</div>
					</div>
					<div class="after-selection-button ">
						<el-button type="info">绑定说明</el-button>
						<el-button type="primary" @click="bangding" plain>开始绑定</el-button>
					</div>

				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import vm from '@/main';
	import axios from 'axios';
	export default {
		data() {
			return {
				activeName: "first",
				shop_id: "",
				isact: 0,
				type: 1,
				dialogVisible: false,
				dialogVisible1: false,
				dialog1: false,
				pageSize: 10,
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}],
				shoplist: [],
				shopinfo: [],
				busyLevel: {
					"others": "未知状态",
					"busyLevelFree": "店铺正常营业",
					"busyLevelClosed": "店铺休息中",
					"busyLevelNetworkUnstable": "店铺网络不稳定/电话订餐",
					"busyLevelHoliday": "店铺休假中"
				}
			}
		},
		created() {
			this.getlist();
			console.log("0")
		},
		methods: {
			bangding() {
				if (this.shop_id == "") {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请输入店铺ID!'
					});
					return;
				}
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('shop_id', this.shop_id)
				formData1.append('act', "list")
				formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/doAuth.aspx'
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 200)
					if (data.data.ret != 0) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: data.data.msg
						});
					} else {
						this.$message({
							showClose: true,
							type: 'success',
							message: "绑定成功"
						});
						//setTimeout(function() {
						//	vm.$router.go(0)
						//}, 2000)
						this.shoplist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			getlist() {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('type', this.type.toString())
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/getShop.aspx'
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 200)
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.shoplist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			handleEdit(index, row) {
				var shoplist = this.shoplist;
				var shopinfo = shoplist[index];
				this.shopinfo = shoplist[index];
				console.log("index", index)
				this.dialog1 = true;
			},
			delClick(index, row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					const formData = new FormData()
					formData.append('id', row.id)
					formData.append('act', "delete")
					formData.append('token', this.$Cookies.get("vue_elm_token"))
					const url = 'http://www.vssite.com/api/getShop.aspx'
					axios.post(url, formData).then(data => {
						if (data.data.ret != 0) {
							this.$message({
								type: 'warning',
								message: data.data.msg
							});
						} else {
							this.$message({
								type: 'success',
								message: "删除成功！"
							});
						}
						setTimeout(() => {
							this.loading = false;
							this.getlist();
						}, 1000)
						console.log('data', data)
					}).catch(response => {
						console.log(response)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				console.log(row);
			},
			handleClick(tab, event) {
				if (tab.index == 1) {
					this.type = 2
				} else {
					this.type = 1
				}
				this.getlist();
				console.log("event", tab)
			},
			wancheng() {
				console.log("2")
			},
			authorize() {
				if (this.type == 2) {
					this.dialogVisible = true;
					this.dialogVisible1 = false;
				} else {
					this.dialogVisible = false;
					this.dialogVisible1 = true;
				}
				this.isact = 0;
			},
			shouquan() {
				this.isact = 1;
				const link = document.createElement("a");
				link.target = "_blank";
				link.href = "http://www.vssite.com/ShopAuthorize.aspx";
				document.body.appendChild(link);
				link.click();
				console.log("2")
			},
			handleClose1() {
				this.dialog1 = false;
				console.log("2")
			},
			handleClose() {
				this.dialogVisible = false;
			},
			handleClose2() {
				this.dialogVisible1 = false;
			}
		}
	}
</script>

<style>
	.after-selection-button {
		display: flex;
		justify-content: space-around;
		padding: 0 30px;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.steps-list {
		text-align: left;
	}

	.steps-list {
		position: relative;
		margin: 0 auto;
		width: 500px;
		text-align: left;
	}

	.steps-list .item {
		position: relative;
		padding: 10px 0;
	}

	.steps-list .item {
		padding: 10px 0;
	}

	.steps-list .item:first-child:before {
		top: 30px;
	}

	.steps-list .item:before {
		position: absolute;
		top: 30px;
		left: -20px;
		width: 2px;
		height: 100%;
		background: #EFEFF4;
		content: " ";
	}

	.steps-list .item:after {
		position: absolute;
		top: 0;
		left: -23px;
		margin-top: 26px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #EFEFF4;
		content: " ";
	}

	.steps-list .item .title {
		margin-bottom: 10px;
		font-weight: 600;
	}

	.dashboard__logo {
		padding: 15px;
	}

	.u-t-flex {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	::v-deep .el-table th {
		text-align: center;
		background-color: #fff !important;
		border-color: "#0F9FFF" !important;
		color: #000 !important;
	}
</style>
